exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-category-index-tsx": () => import("./../../../src/pages/category/index.tsx" /* webpackChunkName: "component---src-pages-category-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakty-index-tsx": () => import("./../../../src/pages/kontakty/index.tsx" /* webpackChunkName: "component---src-pages-kontakty-index-tsx" */),
  "component---src-pages-partnery-index-tsx": () => import("./../../../src/pages/partnery/index.tsx" /* webpackChunkName: "component---src-pages-partnery-index-tsx" */),
  "component---src-pages-pro-stm-trade-index-tsx": () => import("./../../../src/pages/pro-stm-trade/index.tsx" /* webpackChunkName: "component---src-pages-pro-stm-trade-index-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/CategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-product-category-template-tsx": () => import("./../../../src/templates/ProductCategoryTemplate.tsx" /* webpackChunkName: "component---src-templates-product-category-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/ProductTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */)
}

